<template>
  <div>
    <SolutionVoip />
    <SolutionCollaboration />
  </div>
</template>

<script>
import SolutionVoip from '@/components/telephonie/SolutionVoip.vue';
import SolutionCollaboration from '@/components/telephonie/SolutionCollaboration.vue';


export default {
  components: {
    SolutionVoip,
    SolutionCollaboration,

  },
};
</script>