<template>
  <div>
    <section id="collaboration" class="collaboration">
      <h2>Une application multi-usages :</h2>


      <!-- Section 1 -->
      <section class="additional-content" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="image-container animate-from-top rotate-left">
            <img src="@/assets/collab/collab8.jpg" alt="Interface unifiée" class="overflow-image reduced-size" />
          </div>
          <div class="text-content">
            <h2>Interface unifiée</h2>
            <p>Alezia Collaboration offre une plateforme tout-en-un où tous les outils de communication sont centralisés. Que ce soit pour discuter par chat, passer des appels ou organiser des visioconférences, tout se fait à partir d'une interface unique, accessible depuis n'importe quel navigateur web ou via une application dédiée. Cette interface simplifie les interactions et améliore l'efficacité, car il n'est pas nécessaire de basculer entre plusieurs outils ou plateformes.</p>
          </div>
        </div>
      </section>

      <!-- Section 2 -->
      <section class="additional-content reverse" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Appels et gestion des contacts</h2>
            <p>Les utilisateurs peuvent gérer facilement leurs communications téléphoniques grâce à l'intégration VoIP (Voice over IP). Ils peuvent passer et recevoir des appels directement depuis une interface dédiée. En plus de la gestion des appels, les utilisateurs ont accès à un annuaire de contacts centralisé, peuvent consulter l'historique des appels et retrouver rapidement les informations de leurs collègues ou clients. La synchronisation avec les contacts d'autres outils ou CRM est également possible, facilitant la gestion des relations professionnelles.</p>
          </div>
          <div class="image-container animate-scale-up">
            <img src="@/assets/collab/collab2.jpg" alt="Appels et gestion des contacts" class="overflow-image reduced-size" />
          </div>
        </div>
      </section>

      <!-- Section 3 -->
      <section class="additional-content" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="image-container animate-from-top rotate-left">
            <img src="@/assets/collab/collab3.jpg" alt="Chat en temps réel" class="overflow-image" />
          </div>
          <div class="text-content">
            <h2>Chat en temps réel</h2>
            <p>La messagerie instantanée est une fonctionnalité clé de notre solution. Elle permet d'envoyer des messages textes en temps réel à un ou plusieurs collaborateurs, qu’ils soient à proximité ou à distance. Les discussions de groupe peuvent être créées pour favoriser la communication en équipe. Des notifications intégrées garantissent que les utilisateurs restent informés des nouveaux messages, et peuvent répondre rapidement, améliorant ainsi la réactivité des équipes.</p>
          </div>
        </div>
      </section>

      <!-- Section 4 -->
      <section class="additional-content reverse" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Partage de fichiers et de documents</h2>
            <p>Le partage de fichiers est une fonctionnalité essentielle dans un environnement de travail collaboratif. Les utilisateurs peuvent facilement envoyer des fichiers et documents dans les chats ou lors de visioconférences. Cela permet un partage instantané des informations et des documents importants, simplifiant ainsi le travail d'équipe et la collaboration sur des projets en temps réel, sans avoir à passer par des emails ou des outils externes.</p>
          </div>
          <div class="image-container animate-scale-up">
            <img src="@/assets/collab/collab4.jpg" alt="Partage de fichiers" class="overflow-image reduced-size" />
          </div>
        </div>
      </section>

      <!-- Section 5 -->
      <section class="additional-content" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="image-container animate-from-top rotate-left">
            <img src="@/assets/collab/collab5.jpg" alt="Vidéo et conférences audio" class="overflow-image reduced-size" />
          </div>
          <div class="text-content">
            <h2>Vidéo et conférences audio</h2>
            <p>Notre dispositif inclut des outils de visioconférence et de conférence audio, idéals pour organiser des réunions à distance. Les utilisateurs peuvent facilement démarrer des appels vidéo en un clic, partager leur écran pour présenter des documents ou des projets, et inviter plusieurs participants à collaborer. Ces fonctionnalités sont conçues pour favoriser une interaction fluide, même pour les équipes dispersées géographiquement, améliorant ainsi la productivité des réunions.</p>
          </div>
        </div>
      </section>

      <!-- Section 6 -->
      <section class="additional-content reverse" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Présence et disponibilité</h2>
            <p>Un de nos atouts majeurs est l'affichage en temps réel de la disponibilité des collaborateurs. Les utilisateurs peuvent voir si un collègue est disponible, en réunion, absent, ou occupé. Cela permet de planifier la communication plus efficacement et d'éviter d'interrompre les collaborateurs pendant des moments inopportuns. La visibilité sur le statut de chaque personne aide également à réduire les délais d'attente pour des réponses ou des prises de décision.</p>
          </div>
          <div class="image-container animate-scale-up">
            <img src="@/assets/collab/collab6.jpg" alt="Présence et disponibilité" class="overflow-image section-6-image" />
          </div>
        </div>
      </section>

      <!-- Section 7 -->
      <section class="additional-content" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="image-container animate-from-top rotate-left">
            <img src="@/assets/collab/collab7.jpg" alt="Intégration et API" class="overflow-image" />
          </div>
          <div class="text-content">
            <h2>Intégration et API</h2>
            <p>Notre solution est conçue pour s'intégrer facilement avec d'autres logiciels d'entreprise, comme les CRM, ERP, et autres outils de gestion d'entreprise. Grâce à des API (interfaces de programmation), les entreprises peuvent connecter notre solution à leurs systèmes existants, automatiser les processus et améliorer la gestion des interactions clients. Cette intégration permet une meilleure synchronisation des données et améliore l'efficacité globale des opérations.</p>
            <router-link to="/integration" class="integration-button">Vers nos intégrations</router-link>
          </div>
        </div>
      </section>

      <!-- Section 8 -->
      <section class="additional-content reverse" style="background: linear-gradient(180deg, #cde8ff 0%, #a5d3ff 100%);">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Sécurité et cloud</h2>
            <p>Solution entièrement hébergée dans le cloud, les entreprises partenaires bénéficient d'une grande flexibilité et d'une haute disponibilité des services sans avoir à gérer une infrastructure locale. La plateforme respecte les normes de sécurité les plus strictes pour garantir la protection des données et des communications. Des mécanismes avancés de chiffrement sont utilisés pour assurer la confidentialité des informations échangées, tout en offrant une performance stable et une résilience face aux pannes.</p>
          </div>
          <div class="image-container animate-scale-up">
            <img src="@/assets/collab/collab1.png" alt="Sécurité et cloud" class="overflow-image" />
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SolutionCollaboration',
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        }
      });
    }, { threshold: 0.8 });

    const animatedElements = document.querySelectorAll('.image-container');
    animatedElements.forEach((el) => observer.observe(el));
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.collaboration {
  padding: 4rem 2rem;
  padding-top: 8rem;
}

.collaboration h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

.collaboration .description {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 1.75rem;
  color: #007BFF;
}

.additional-content {
  padding: 4rem 2rem;
  position: relative;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
  transition: transform 0.3s ease;
}

.additional-content:hover {
  transform: translateY(-10px);
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
}

.text-content {
  width: 55%;
  text-align: left;
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #007BFF;
}

.text-content p {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555;
}

.image-container {
  width: 45%;
  display: flex;
  justify-content: center;
  overflow: visible;
}

.image-container.animate-from-left {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.image-container.animate-from-right {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.image-container.animate-from-top {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.image-container.animate-scale-up {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.image-container.animate-from-left.in-view,
.image-container.animate-from-right.in-view,
.image-container.animate-from-top.in-view,
.image-container.animate-scale-up.in-view {
  opacity: 1;
  transform: translateX(0) translateY(0) scale(1);
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  transform: perspective(1000px) rotateY(-10deg);
  transition: transform 1s ease;
  background-color: white;
}

.image-container.rotate-left img {
  transform: perspective(1000px) rotateY(10deg);
}

.image-container.in-view img {
  transform: perspective(1000px) rotateY(-10deg);
}

.image-container.rotate-left.in-view img {
  transform: perspective(1000px) rotateY(10deg);
}

.image-container img.reduced-size {
  max-width: 70%;
}

.image-container img.section-6-image {
  max-width: 70%;
}

.overflow-image {
  position: relative;
  width: 100%;
  left: 0;
}

.reverse {
  flex-direction: row-reverse;
}

.integration-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #007BFF;
  color: white;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.integration-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .text-content, .image-container {
    width: 100%;
  }

  .text-content {
    text-align: center;
  }
}
</style>
